<template>
  <vca-card>
    <div class="desktop-view">
      <vca-row style="position: relative; width: max-content; float: right">
        <button
          class="vca-button-small"
          @click="event_view = 'table'"
          :class="{ 'active-select': event_view == 'table' }"
        >
          {{ $t("events.list.list") }}
        </button>
        <button
          class="vca-button-small"
          @click="event_view = 'cards'"
          :class="{ 'active-select': event_view == 'cards' }"
        >
          {{ $t("events.list.cards") }}
        </button>
      </vca-row>
    </div>
    <h2>{{ $t("events.list.header") }}</h2>
    <p v-if="!session">{{ $t("events.list.info_nosession") }}</p>
    <p v-else>{{ $t("events.list.info") }}</p>
    <div class="mobile-view">
      <vca-row style="margin: 10px auto">
        <button
          style="margin: 0 auto"
          class="vca-button-small"
          @click="event_view = 'table'"
          :class="{ 'active-select': event_view == 'table' }"
        >
          {{ $t("events.list.list") }}
        </button>
        <button
          style="margin: 0 auto"
          class="vca-button-small"
          @click="event_view = 'cards'"
          :class="{ 'active-select': event_view == 'cards' }"
        >
          {{ $t("events.list.cards") }}
        </button>
      </vca-row>
    </div>
    <vca-card class="shadowed vca-center">
      <vca-column style="width: 100%">
        <vca-accordion class="desktop-view" :header="$t('events.list.filter')">
          <div slot="body">
            <EventFilter v-model="filter" />
          </div>
        </vca-accordion>
        <div class="mobile-view">
          <button class="vca-button" @click="do_filter = true">
            {{ $t("events.list.filter") }}
          </button>
          <vca-popup
            v-if="do_filter"
            :show="do_filter"
            :title="$t('events.list.filter')"
            @close="do_filter = false"
          >
            <EventFilter v-model="filter" />
          </vca-popup>
        </div>

        <div v-if="event_view == 'cards'">
          <EventCards @sort="sort" :list="list" :filter="filter" />
        </div>
        <div v-else>
          <AsyncEventTable />
        </div>
      </vca-column>
      <vca-popup
        v-if="current != null"
        :show="current != null"
        :title="$t('events.popup.view', { 0: current.name })"
        @close="current = null"
      >
        <EventApplication @close="current = null" :event="current" />
      </vca-popup>
    </vca-card>
  </vca-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import EventFilter from "@/components/events/list/EventFilter";
import EventApplication from "@/components/events/application/EventApplication";
import AsyncEventTable from "../../components/events/AsyncEventTable.vue";
import EventCards from "@/components/events/EventCards";
export default {
  name: "EventsList",
  components: {
    EventCards,
    EventFilter,
    EventApplication,
    AsyncEventTable,
  },
  data() {
    return {
      store: "events/pg",
      do_filter: false,
      initialized: false,
      currentFilter: {
        type: [],
        crews: [],
        entities: [],
        start_date: null,
        end_date: null,
        event_state: [],
        only_apply: true,
        missing_applications: false,
      },
    };
  },
  created() {
    if (!this.$route.params.id) {
      this.current = null;
    }

    this.$store.commit("events/pg/route", "events/event/public");
    this.$store.commit("events/pg/context", "events/pg");

    if (Object.keys(this.filter).length == 0) {
      this.filter = this.currentFilter;
    }

    if (
      this.hasPoolPermission(this.poolEventPermissions) ||
      this.hasSystemPermission()
    ) {
      this.filter.only_apply = false;
      this.filter.event_state = [
        "created",
        "requested_crew",
        "requested_internal",
        "published",
      ];
    } else {
      this.filter.event_state = ["published"];
    }

    if (
      this.hasPoolPermission(this.poolEventPermissions) ||
      this.hasSystemPermission() ||
      this.session
    ) {
      this.$store.commit("events/pg/route", "events/event");
      this.pg.currentSortDir = "desc";
      this.$store
        .dispatch({ type: "events/pg/request", data: { initial: true } })
        .then(() => {
          if (this.$route.params.id) {
            this.current = this.pg.list.find(
              (el) => el.id == this.$route.params.id
            );
          }
        });
    } else {
      this.pg.currentSortDir = "desc";
      this.$store
        .dispatch({ type: "events/pg/request", data: { initial: true } })
        .then(() => {
          if (this.$route.params.id) {
            this.current = this.pg.list.find(
              (el) => el.id == this.$route.params.id
            );
          }
        });
    }

    const type = this.hasSystemPermission() ? "crews/list" : "crews/public";
    this.$store.dispatch({ type: type }).finally(() => {
      this.initialized = true;
    });
    if (this.session) {
      this.$store
        .dispatch({ type: "events/participations/listUser" })
        .then((response) => {
          this.$store.commit(
            "events/participations/list",
            !response || response.length == 0 ? [] : response
          );
        });
    }
  },

  watch: {
    "pg.currentPage": {
      handler(value) {
        if (this.initialized) {
          this.initialized = false;
          this.$store.commit(`${this.store}/currentPage`, value);
          this.$store
            .dispatch({
              type: `${this.store}/request`,
              data: { abort: true },
            })
            .then(() => {
              this.initialized = true;
            });
        }
      },
      deep: true,
    },
    "pg.pageSize": {
      handler(value) {
        if (this.initialized) {
          this.initialized = false;
          this.$store.commit(`${this.store}/pageSize`, value);
          if (
            this.pg.currentPageSize == 1 ||
            this.pg.listSize != this.pg.currentPageSize
          ) {
            this.$store
              .dispatch({
                type: `${this.store}/request`,
                data: { abort: true },
              })
              .finally(() => {
                this.initialized = true;
              });
          }
        }
      },
      deep: true,
    },
    "pg.filterObject": {
      handler() {
        if (this.initialized) {
          this.initialized = false;
          this.$store
            .dispatch({
              type: `${this.store}/request`,
              data: { initial: true, abort: true },
            })
            .then(() => {
              this.$store.commit(`${this.store}/adjustPageSize`);
              if (this.pg.pageSize < this.pg.listSize) {
                this.$store
                  .dispatch({
                    type: `${this.store}/request`,
                    data: { initial: true, abort: true },
                  })
                  .finally(() => {
                    this.initialized = true;
                  });
              } else {
                this.initialized = true;
              }
            });
        }
      },
      deep: true,
    },
    "pg.filter": {
      handler() {
        this.$store
          .dispatch({
            type: `${this.store}/request`,
            data: { initial: true, abort: true },
          })
          .then(() => {
            this.initialized = false;
            this.$store.commit(`${this.store}/adjustPageSize`);
            if (this.pg.pageSize < this.pg.listSize) {
              this.$store
                .dispatch({
                  type: `${this.store}/request`,
                  data: { initial: true, abort: true },
                })
                .finally(() => {
                  this.initialized = true;
                });
            } else {
              this.initialized = true;
            }
          });
      },
      deep: true,
    },
  },
  computed: {
    filter: {
      set(value) {
        this.pg.filterObject = value;
      },
      get() {
        return this.pg.filterObject;
      },
    },
    ...mapState({
      pg(state, getters) {
        if (this.store) {
          return getters[`${this.store}/pagination`];
        }
      },
    }),
    event_view: {
      get() {
        return this.$store.state.user.settings.current.event_view;
      },
      set(value) {
        this.$store.commit("user/settings/current", {
          ...this.settings,
          ...{ event_view: value },
        });
      },
    },
    ...mapGetters({
      session: "session",
      user: "user/current",
      list: "events/pg/list",
      settings: "user/settings/current",
      poolEventPermissions: "user/roles/poolEventPermissions",
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
    current: {
      get() {
        return this.$store.state.events.current;
      },
      set(value) {
        this.$store.commit("events/current", value);
      },
    },
  },
  methods: {
    async sort(col) {
      await this.$store.dispatch({
        type: this.store + "/sort",
        col,
      });
      return;
    },
  },
};
</script>
<style lang="scss">
.active-select {
  color: white;
  background-color: $blue-dark;
  border: solid thin $blue-dark;
}
</style>
